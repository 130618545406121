* {
  -webkit-user-select: none; /* Safari and older versions of Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard */
}

a {
  text-decoration: none;
}

body,
html {
  overflow: visible;
  margin: 0;
}
@font-face {
  font-family: "S-Core_Dream";
  src: url("/public/font/SCDream4.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
